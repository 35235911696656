#muiTableData>div>div>table>thead>tr>th>span{
  justify-content: center;
  text-align: center;
  font-weight: bold;
  color: black;
  display: flex;
}
#muiTableData>div>div>table>thead>tr>th>div{
  justify-content: center;
  /* color: black;
  font-weight: bold; */
  text-align: center;
  display: flex;
}

.MuiInputBase-input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #F6F7FF inset !important;
}

.MuiSnackbar-anchorOriginBottomLeft {
  display: "none !important";
}
